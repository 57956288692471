<template>
  <ul class="links text-shadow" style="margin-left:10px">
    <li style="pointer-events:auto;">
      <nuxt-link :to="{ name: 'Inn-innId' }">
        <div>
          <div style="background-color:#fff;border-radius:50px;display:inline-block;padding:2px">
            <div style="display:flex">
              <svg width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="white" fill-opacity="0.01" /><path d="M9 18V42H39V18L24 6L9 18Z" fill="none" /><path d="M9 42V18L4 22L24 6L44 22L39 18V42H9Z" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="bevel" /><path d="M19 29V42H29V29H19Z" fill="none" stroke="#000" stroke-width="2" stroke-linejoin="bevel" /><path d="M9 42H39" stroke="#000" stroke-width="2" stroke-linecap="round" /></svg>
            </div>
          </div>
        </div>
        客栈主页
      </nuxt-link>
    </li>
    <li v-if="pano.gpsLat!=0||pano.gpsLng!=0" style="pointer-events:auto;">
      <a href="javascript:" @click="$emit('mapView')">
        <div>
          <div style="background-color:#fff;border-radius:50px;display:inline-block;padding:2px">
            <div style="display:flex">
              <svg width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="white" fill-opacity="0.01" /><path d="M24 44C24 44 39 32 39 19C39 10.7157 32.2843 4 24 4C15.7157 4 9 10.7157 9 19C9 32 24 44 24 44Z" fill="none" stroke="#000" stroke-width="2" stroke-linejoin="bevel" /><path d="M24 25C27.3137 25 30 22.3137 30 19C30 15.6863 27.3137 13 24 13C20.6863 13 18 15.6863 18 19C18 22.3137 20.6863 25 24 25Z" fill="none" stroke="#000" stroke-width="2" stroke-linejoin="bevel" /></svg>
            </div>
          </div>
        </div>
        导航
      </a>
    </li>
    <li v-if="inn.reserveUrl" style="pointer-events:auto;">
      <a :href="inn.reserveUrl" target="_blank">
        <div>
          <div style="background-color:#fff;border-radius:50px;display:inline-block;padding:2px">
            <div style="display:flex">
              <svg width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="white" fill-opacity="0.01" /><path d="M39 32H13L8 12H44L39 32Z" fill="none" /><path d="M3 6H6.5L8 12M8 12L13 32H39L44 12H8Z" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="bevel" /><circle cx="13" cy="39" r="3" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="bevel" /><circle cx="39" cy="39" r="3" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="bevel" /></svg>
            </div>
          </div>
        </div>
        订房
      </a>
    </li>
    <li style="pointer-events:auto;" v-if="inn.user&&inn.user.phoneNumber">
      <a :href="'tel:'+inn.user.phoneNumber" target="_blank">
        <div>
          <div style="background-color:#fff;border-radius:50px;display:inline-block;padding:2px">
            <div style="display:flex">
              <svg width="16" height="16" viewBox="0 0 52 51" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" transform="translate(3 1)" fill="white" fill-opacity="0.01" /><rect x="3" y="1" width="48" height="48" fill="white" fill-opacity="0.01" /><path d="M44.7796 21.6066C45.0324 19.9108 44.9495 18.1747 44.5309 16.5054C43.978 14.3002 42.8392 12.2118 41.1147 10.4873C39.3902 8.76281 37.3018 7.62409 35.0967 7.07115C33.4274 6.65257 31.6912 6.56967 29.9954 6.82245" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="bevel" /><path d="M37.1897 20.8035C37.4605 18.9869 36.8967 17.0699 35.4983 15.6715C34.0998 14.2731 32.1829 13.7092 30.3663 13.98" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="bevel" /><path d="M17.3757 9.79423C18.1022 9.79423 18.7716 10.1883 19.1243 10.8235L21.5707 15.2303C21.891 15.8073 21.9061 16.5052 21.6109 17.0955L19.2541 21.8091C19.2541 21.8091 19.9371 25.3206 22.7955 28.179C25.654 31.0374 29.1536 31.7086 29.1536 31.7086L33.8665 29.3522C34.4572 29.0568 35.1556 29.0721 35.7328 29.393L40.1521 31.85C40.7868 32.2028 41.1803 32.8719 41.1803 33.598L41.1803 38.6715C41.1803 41.2552 38.7804 43.1213 36.3323 42.2952C31.3044 40.5987 23.4997 37.3685 18.5529 32.4216C13.606 27.4748 10.3758 19.6701 8.67929 14.6422C7.85326 12.1941 9.71935 9.79423 12.303 9.79423L17.3757 9.79423Z" fill="none" stroke="#000" stroke-width="2" stroke-linejoin="bevel" /></svg>
            </div>
          </div>
        </div>
        电话
      </a>
    </li>
    <!--</ul>
    <ul id="links" class="links">-->
    <template>
      <li v-for="i in pano.links" style="pointer-events:auto;">
        <a v-if="i.type=='Url'" :title="i.title" :href="i.link" target="_blank">
          <div>
            <div style="background-color:#fff;border-radius:50px;display:inline-block;padding:3px">
              <div style="display:flex;pointer-events:none;">
                <svg width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="white" fill-opacity="0.01" /><path d="M43 16V42H5V16V16" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /><path d="M5 4H43V16H5V4Z" fill="none" stroke="#333" stroke-width="2" stroke-linejoin="round" /><path fill-rule="evenodd" clip-rule="evenodd" d="M11 12C12.1046 12 13 11.1046 13 10C13 8.89543 12.1046 8 11 8C9.89543 8 9 8.89543 9 10C9 11.1046 9.89543 12 11 12Z" fill="#333" /><path fill-rule="evenodd" clip-rule="evenodd" d="M17 12C18.1046 12 19 11.1046 19 10C19 8.89543 18.1046 8 17 8C15.8954 8 15 8.89543 15 10C15 11.1046 15.8954 12 17 12Z" fill="#333" /><path fill-rule="evenodd" clip-rule="evenodd" d="M23 12C24.1046 12 25 11.1046 25 10C25 8.89543 24.1046 8 23 8C21.8954 8 21 8.89543 21 10C21 11.1046 21.8954 12 23 12Z" fill="#333" /></svg>
              </div>
            </div>
          </div>
          {{i.title}}
        </a>
        <a v-else-if="i.type=='Email'" :title="i.title" :href="'mailto:'+i.link">
          <div>
            <div style="background-color:#fff;border-radius:50px;display:inline-block;padding:3px">
              <div style="display:flex;pointer-events:none;">
                <svg width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="white" fill-opacity="0.01" /><path d="M4 39H44V24V9H24H4V24V39Z" fill="none" stroke="#000" stroke-width="1" stroke-linejoin="bevel" /><path d="M4 9L24 24L44 9" stroke="#000" stroke-width="1" stroke-linecap="round" stroke-linejoin="bevel" /><path d="M24 9H4V24" stroke="#000" stroke-width="1" stroke-linecap="round" stroke-linejoin="bevel" /><path d="M44 24V9H24" stroke="#000" stroke-width="1" stroke-linecap="round" stroke-linejoin="bevel" /></svg>
              </div>
            </div>
          </div>
          {{i.title}}
        </a>
        <a v-else-if="i.type=='Tel'" :title="i.title" :href="'tel:'+i.link">
          <div>
            <div style="background-color:#fff;border-radius:50px;display:inline-block;padding:3px">
              <div style="display:flex;pointer-events:none;">
                <svg width="16" height="16" viewBox="0 0 52 51" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" transform="translate(3 1)" fill="white" fill-opacity="0.01" /><rect x="3" y="1" width="48" height="48" fill="white" fill-opacity="0.01" /><path d="M44.7796 21.6066C45.0324 19.9108 44.9495 18.1747 44.5309 16.5054C43.978 14.3002 42.8392 12.2118 41.1147 10.4873C39.3902 8.76281 37.3018 7.62409 35.0967 7.07115C33.4274 6.65257 31.6912 6.56967 29.9954 6.82245" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /><path d="M37.1897 20.8035C37.4605 18.9869 36.8967 17.0699 35.4983 15.6715C34.0998 14.2731 32.1829 13.7092 30.3663 13.98" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /><path d="M17.3757 9.79423C18.1022 9.79423 18.7716 10.1883 19.1243 10.8235L21.5707 15.2303C21.891 15.8073 21.9061 16.5052 21.6109 17.0955L19.2541 21.8091C19.2541 21.8091 19.9371 25.3206 22.7955 28.179C25.654 31.0374 29.1536 31.7086 29.1536 31.7086L33.8665 29.3522C34.4572 29.0568 35.1556 29.0721 35.7328 29.393L40.1521 31.85C40.7868 32.2028 41.1803 32.8719 41.1803 33.598L41.1803 38.6715C41.1803 41.2552 38.7804 43.1213 36.3323 42.2952C31.3044 40.5987 23.4997 37.3685 18.5529 32.4216C13.606 27.4748 10.3758 19.6701 8.67929 14.6422C7.85326 12.1941 9.71935 9.79423 12.303 9.79423L17.3757 9.79423Z" fill="none" stroke="#333" stroke-width="2" stroke-linejoin="round" /></svg>
              </div>
            </div>
          </div>
          {{i.title}}
        </a>
        <a v-else-if="i.type=='Location'" :title="i.title" @click="linkShowView(i)">
          <div>
            <div style="background-color:#fff;border-radius:50px;display:inline-block;padding:3px">
              <div style="display:flex;pointer-events:none;">
                <svg width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="white" fill-opacity="0.01" /><path d="M24 20C28.4183 20 32 16.4183 32 12C32 7.58172 28.4183 4 24 4C19.5817 4 16 7.58172 16 12C16 16.4183 19.5817 20 24 20Z" fill="none" stroke="#333" stroke-width="2" stroke-linejoin="round" /><path d="M24 20V38" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /><path d="M16 32H12L4 44H44L36 32H32" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /></svg>
              </div>
            </div>
          </div>
          {{i.title}}
        </a>
        <a v-else-if="i.type=='Image'" :title="i.title" @click="linkShowView(i)">
          <div>
            <div style="background-color:#fff;border-radius:50px;display:inline-block;padding:3px">
              <div style="display:flex;pointer-events:none;">
                <svg width="16" height="16" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><g><rect width="48" height="48" fill="white" fill-opacity="0.01" stroke-linejoin="round" stroke-width="2" stroke="none" fill-rule="evenodd" /><g transform="translate(6.000000, 6.000000)"><rect x="0" y="0" width="14" height="11" stroke-linejoin="round" stroke-width="2" stroke="#333" fill="none" fill-rule="nonzero" /><rect x="22" y="25" width="14" height="11" stroke-linejoin="round" stroke-width="2" stroke="#333" fill="none" fill-rule="nonzero" /><rect x="22" y="0" width="14" height="17" stroke-linejoin="round" stroke-width="2" stroke="#333" fill="none" fill-rule="nonzero" /><rect x="0" y="19" width="14" height="17" stroke-linejoin="round" stroke-width="2" stroke="#333" fill="none" fill-rule="nonzero" /></g></g></svg>
              </div>
            </div>
          </div>
          {{i.title}}
        </a>
      </li>
    </template>
  </ul>
</template>
<script>
  export default {
    components: {
    },
    props: {
    },
    data() {
      return {
      }
    },
    inject: {
      publicData: {
        default: {}
      }
    },
    computed: {
      inn() {
        return this.publicData.inn || {}
      },
      pano() {
        return this.publicData.pano || {}
      },
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
          linkShowView: {
            target: 'linkView', name: 'linkShowView',
          },
          mapView: {
            target: 'panoMap', name: 'showAndNavigation',
          }
        },
        funcs: {
        }
      })
    },
    destroyed() {
    },
    methods: {
    },
  }
</script>
<style scoped>
  .links {
    list-style: none;
    font-size: 0.8rem;
    margin: 0;
    opacity: .6
  }

    .links li {
      display: inline-block;
      margin: 0 5px;
    }

      .links li i {
        display: block;
      }

      .links li a {
        text-align: center;
        color: #fff
      }
</style>
